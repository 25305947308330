import { createRouter, createWebHashHistory } from 'vue-router'
import useProfileStore from '@/store/profile.js'

const routes = [
  /*{
    path: '/',
    component: () => import('@/layouts/default/DefaultBHP.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/HomeBHP.vue'),
      },
    ],
    meta: {
      requireAuth: true
    }
  },*/
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/CensoBHP.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginBHP.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/MenuBHP.vue')
  },
  {
    path: '/actividades',
    name: 'actividades',
    component: () => import('@/views/ActividadesIndex.vue')
  },
  {
    path: '/inspeccion',
    name: 'inspeccion',
    component: () => import('@/views/InspeccionBHP.vue'),
    meta: {
      requireAuth: true
    }
  },/*,
  {
    path: '/inspeccion/:idInspeccion',
    name: 'inspeccion',
    component: () => import('@/views/Inspeccion.vue')
  },*/
  {
    path: '/checklist/:idInspeccion',
    name: 'checklist',
    component: () => import('@/views/ChecklistBHP.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/censo',
    name: 'censo',
    component: () => import('@/views/CensoBHP.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutBHP.vue'),
    meta: {
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  //console.log(to)
  //console.log(from)
  //console.log(next)
  var auth = false
  const profileStore = useProfileStore();
  if(localStorage.getItem('perfil') != null){
    if(JSON.parse(localStorage.getItem('perfil')).auth){
      profileStore.auth = true;
      auth = JSON.parse(localStorage.getItem('perfil')).auth
    }
  }
  const needAuth = to.meta.requireAuth
  //var auth = profileStore.auth;

  if (needAuth && !auth) {
    router.push({
      path: "/login",
    })
  } else {
    
    next()
  }
})

export default router
